import { PredictionContext } from "contexts/Prediction";
import { useCallback, useContext } from "react";
import { useChatWithAi } from "utils/HandleAiResponse";
import Header from "../Header";
import MicrophoneButton from "../MicrophoneButton";
import Theia from "../Theia";
import classes from "./classes.module.scss";

type IProps = {};

export default function Discussion(props: IProps) {
	const predictionContext = useContext(PredictionContext);
	const chatWithAi = useChatWithAi();
	const onMicrophoneStart = useCallback(() => predictionContext.data.audio?.pause(), [predictionContext.data.audio]);
	const onMicrophoneEnd = useCallback((transcript: string) => chatWithAi(transcript), [chatWithAi]);

	return (
		<div className={classes["root"]}>
			<Header className={classes["header"]} />

			<div className={classes["content"]}>
				<div className={classes["content-container"]}>
					<Theia />

					<div className={classes["text-container"]}>
						<p className={classes["text"]}>{predictionContext.data.message}</p>
					</div>
				</div>

				<div className={classes["microphone-button-container"]}>
					<MicrophoneButton onStart={onMicrophoneStart} onEnd={onMicrophoneEnd} />
				</div>
			</div>
		</div>
	);
}
