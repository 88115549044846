import { useEffect, useState } from "react";
import classes from "./classes.module.scss";

export default function Loader() {
	const [squares, setSquares] = useState(Array(25).fill(false));
	const [isReversing, setIsReversing] = useState(false); // Nouvel état pour suivre la direction de l'animation
	const animationDuration = 4000; // Durée de l'animation du loader en millisecondes

	useEffect(() => {
		const interval = setInterval(() => {
			// Remettre à zéro les carrés et inverser la direction à chaque intervalle
			setSquares(Array(25).fill(false));
			setIsReversing((prevIsReversing) => !prevIsReversing);
		}, animationDuration);

		return () => clearInterval(interval);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		squares.forEach((_, i) => {
			const delay = (animationDuration / 25) * calculateOrder(i, isReversing);

			setTimeout(() => {
				setSquares((prevSquares) => {
					const newSquares = [...prevSquares];
					newSquares[i] = true; // Mettre à jour la couleur du carré
					return newSquares;
				});
			}, delay);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isReversing]);

	// Modifier la fonction pour prendre en compte la direction de l'animation
	function calculateOrder(index: number, isReversing: boolean) {
		let row = Math.floor(index / 5);
		let col = index % 5;
		let order = row % 2 === 0 ? index : (row + 1) * 5 - col - 1;
		return isReversing ? 24 - order : order; // Inverser l'ordre si l'animation est en train de reculer
	}

	return (
		<div className={classes["loader-container"]}>
			{squares.map((active, i) => (
				<div key={i} className={classes["loader-square"]} style={{ backgroundColor: active ? "white" : "transparent" }} />
			))}
		</div>
	);
}
