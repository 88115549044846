import LazyImage from "components/elements/LazyImage";
import Header from "../Header";
import classes from "./classes.module.scss";
import TheiaAvatar from "assets/images/theia.png";
import Button, { EButtonVariant } from "components/elements/Button";
import { useContext, useEffect, useState } from "react";
import { PredictionContext } from "contexts/Prediction";
import { container } from "tsyringe";
import ApplicationConfig from "configs/ApplicationConfig";
import ModuleConfig from "configs/ModuleConfig";

type IProps = {};

export default function AdviceGlasses(props: IProps) {
	const predictionContext = useContext(PredictionContext);

	const [redirectUrl, setRedirectUrl] = useState<string>("");
	useEffect(() => {
		const url = new URL(container.resolve(ApplicationConfig).get().vto.url);
		predictionContext.data.recommandedGlasses.forEach((glasses) => {
			url.searchParams.append("products", glasses);
		});
		url.searchParams.append("callbackUrl", window.location.origin + container.resolve(ModuleConfig).get().modules.pages.ThankYou.props.path);
		setRedirectUrl(url.toString());
	}, [predictionContext]);

	const [topGlass1, topGlass2, topGlass3, topGlass4, topGlass5] = predictionContext.data.recommandedGlasses;
	const sortedRecommandedGlasses = [topGlass4, topGlass2, topGlass1, topGlass3, topGlass5].filter((glass): glass is string => glass !== undefined);

	return (
		<div className={classes["root"]}>
			<Header />
			<div className={classes["content-container"]}>
				<LazyImage src={TheiaAvatar} alt="Theia" className={classes["theia-avatar"]} />

				<div className={classes["text-container"]}>
					<p className={classes["title"]}>HERE IT IS!</p>
					<p className={classes["subtitle"]}>{predictionContext.data.message}</p>
				</div>
				<div className={classes["results-container"]}>
					<div className={classes["glasses-container"]}>
						{sortedRecommandedGlasses.map((glasses) => (
							<LazyImage src={`/${glasses}.png`} className={classes["glasses"]} />
						))}
					</div>

					<div className={classes["results"]}>
						<p className={classes["title"]}>5 results</p>
						<p className={classes["subtitle"]}>Try them in different colors!</p>
					</div>
				</div>

				<div className={classes["button-container"]}>
					<Button variant={EButtonVariant.CONTAINED} fullwidth onClick={() => (window.location.href = redirectUrl)}>
						Try them on
					</Button>
				</div>
			</div>
		</div>
	);
}
