import { container } from "tsyringe";
import React, { PropsWithChildren, useEffect, useState } from "react";
import CookieService from "services/CookieService";

const cookieService = container.resolve(CookieService);

type IAuthContext = {
	sessionId: string | null;
};

type IProps = PropsWithChildren;

export const AuthContext = React.createContext<IAuthContext>(undefined!);

export function AuthProvider(props: IProps) {
	const initialized = React.useRef(false);
	const [sessionId, setSessionId] = useState<string | null>(null);

	// At initialization, we try to get the sessionId from the cookies (autoconnect)
	useEffect(() => {
		if (initialized.current) return;
		initialized.current = true;
		setSessionId(cookieService.getSessionId());
	}, []);

	// When the jwt pair changes, we update the cookies
	useEffect(() => {
		const onChange = ((e: CustomEvent<string | null>) => setSessionId(e.detail)) as EventListener;
		document.addEventListener("session_id_change", onChange);
		return () => {
			document.removeEventListener("session_id_change", onChange);
		};
	}, []);

	return <AuthContext.Provider value={{ sessionId }}>{props.children}</AuthContext.Provider>;
}
