import LazyImage from "components/elements/LazyImage";
import Header from "../Header";
import classes from "./classes.module.scss";
import TheiaAvatar from "assets/images/theia.png";
import VocalAnimation from "assets/images/vocal-animation.gif";
import { useContext, useEffect } from "react";
import { PredictionContext } from "contexts/Prediction";
import { usePredict } from "utils/HandleAiResponse";

type IProps = {};

export default function Predict(props: IProps) {
	const predictionContext = useContext(PredictionContext);
	const predict = usePredict();

	useEffect(() => {
		predict();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className={classes["root"]}>
			<Header />
			<div className={classes["content-container"]}>
				<div className={classes["header"]}>
					<LazyImage src={VocalAnimation} className={classes["vocal-animation"]} />
					<div className={classes["theia-container"]}>
						<LazyImage src={TheiaAvatar} alt="Theia" className={classes["theia-avatar"]} />
					</div>
					<LazyImage src={VocalAnimation} className={classes["vocal-animation"]} />
				</div>

				<div className={classes["text-container"]}>
					<p className={classes["title"]}>You will look amazing !</p>
					<p className={classes["subtitle"]}>{predictionContext.data.message}</p>
				</div>
			</div>
		</div>
	);
}
