import LazyImage from "components/elements/LazyImage";
import classes from "./classes.module.scss";
import TransitionsLogoBlack from "assets/images/logos/transitions-logo-black.svg";
import Button, { EButtonVariant } from "components/elements/Button";
import { Link } from "react-router-dom";

type IProps = {
	onAccept: () => void;
};

export default function AcceptConsent(props: IProps) {
	return (
		<div className={classes["root"]}>
			<LazyImage src={TransitionsLogoBlack} className={classes["logo"]} />
			<div className={classes["text-container"]}>
				<p className={classes["title"]}>YOUR CONSENT</p>
				<div className={classes["sub-text-container"]}>
					<p className={classes["text"]}>
						We need your consent to proceed and process your biometric data to provide you with eyewear recommendations and access to our virtual try on experience.
						Your biometric data will be processed in real time on your device and will never be stored on our systems.
					</p>
					<p className={classes["text"]}>
						By continuing you accept the{" "}
						<Link to="https://www.transitions.com/en-us/privacy-policy/" className={classes["link"]} target="_blank" rel="noreferrer">
							Privacy Policy
						</Link>
					</p>
				</div>
			</div>
			<Button variant={EButtonVariant.CONTAINED} className={classes["button"]} onClick={props.onAccept}>
				ACCEPT
			</Button>
		</div>
	);
}
