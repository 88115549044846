import { useCallback, useContext, useState } from "react";
import Header from "../Header";
import Theia from "../Theia";
import classes from "./classes.module.scss";
import Button, { EButtonVariant } from "components/elements/Button";
import { useChatWithAi } from "utils/HandleAiResponse";
import { PredictionContext } from "contexts/Prediction";
import Slider from "components/elements/Slider";

type IProps = {};

const options = ["fair", "light", "medium", "tan", "dark"];

export default function SkinTone(props: IProps) {
	const predictionContext = useContext(PredictionContext);
	const chatWithAi = useChatWithAi();
	const [skinTone, setSkinTone] = useState(0);

	const stopAudio = useCallback(() => predictionContext.data.audio?.pause(), [predictionContext.data.audio]);

	return (
		<div className={classes["root"]}>
			<Header />
			<div className={classes["content-container"]}>
				<div className={classes["theia-container"]}>
					<Theia />
					<p className={classes["text"]}>{predictionContext.data.message}</p>
				</div>
				<div className={classes["skin-tone-container"]}>
					<Slider value={skinTone} onChange={setSkinTone} />
				</div>
				<div className={classes["buttons-container"]}>
					<Button
						variant={EButtonVariant.CONTAINED}
						fullwidth
						onClick={() => {
							stopAudio();
							chatWithAi(`Skin tone is ${options[skinTone]}`);
						}}>
						Confirm
					</Button>
				</div>
			</div>
		</div>
	);
}
