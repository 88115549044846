import classNames from "classnames";
import classes from "./classes.module.scss";
import { useCallback } from "react";
import MaleIcon from "assets/images/icons/male.svg";
import FemaleIcon from "assets/images/icons/female.svg";
import UnisexIcon from "assets/images/icons/unisex.svg";
import LazyImage from "components/elements/LazyImage";

type IProps = {
	id: string;
	name: string;
	checked: boolean;
	onChange: React.ChangeEventHandler<HTMLInputElement>;
	label: string;
};

export default function CheckboxZone(props: IProps) {
	const handleClick = useCallback(
		(event: React.MouseEvent) => {
			event.preventDefault();
			document.getElementById(props.id)?.click();
		},
		[props.id],
	);

	return (
		<div onClick={handleClick} className={classes["root"]}>
			{/* 
                Checkbox buttons are hidden and custom styles are applied to the labels. 
                This allows for full customization of the checkbox button appearance 
                while maintaining the original input functionality.
            */}
			<input type="checkbox" name={props.name} checked={props.checked} onChange={props.onChange} id={props.id} className={classes["checkbox-input"]} />
			<div className={classes["ghost"]} />
			<div className={classes["content-container"]}>
				{props.id === "1" && <LazyImage src={MaleIcon} className={classes["icon"]} />}
				{props.id === "2" && <LazyImage src={FemaleIcon} className={classes["icon"]} />}
				{props.id === "3" && <LazyImage src={UnisexIcon} className={classes["icon"]} />}
				<p className={classes["text"]}>{props.label}</p>
			</div>
			<label
				htmlFor={props.id}
				className={classNames(classes["checkbox-label"], {
					[classes["checked"]!]: props.checked,
				})}
			/>
		</div>
	);
}
