import React, { PropsWithChildren, useCallback, useState } from "react";
import { EFaceShape } from "utils/EFaceShape";
import { FrameGender } from "utils/FrameGender";
import { FrameStyle } from "utils/FrameStyle";
import { SkinTone } from "utils/SkinTone";

export enum PredictionStep {
	START = "START",
	DISCUSSION = "DISCUSSION",
	START_DIAGNOSIS = "START_DIAGNOSIS",
	FACE_SCAN = "FACE_SCAN",
	SKIN_TONE = "SKIN_TONE",
	FRAME_GENDER = "FRAME_GENDER",
	FRAME_STYLE = "FRAME_STYLE",
	RETRIEVE_USER_DATA = "RETRIEVE_USER_DATA",
	ADVICE_GLASSES_BY_ID_WIDGET = "ADVICE_GLASSES_BY_ID_WIDGET",
}
export type IPredictionData = {
	step: PredictionStep;
	message: string | null;
	audio: HTMLAudioElement | null;
	skinTone: SkinTone | null;
	frameGender: FrameGender | null;
	frameStype: FrameStyle | null;
	faceShape: EFaceShape | null;
	recommandedGlasses: string[];
};

type IProps = PropsWithChildren;

const defaultContext: IPredictionData = {
	step: PredictionStep.START,
	message: null,
	audio: null,
	skinTone: null,
	frameGender: null,
	frameStype: null,
	faceShape: null,
	recommandedGlasses: [],
};

export type IPredictionContext = {
	prediction: IPredictionContext;
	update: (context: IPredictionContext) => {};
};

export const PredictionContext = React.createContext({ data: defaultContext, update: (data: IPredictionData) => {}, setStep: (step: PredictionStep) => {} });

export function Prediction(props: IProps) {
	const [data, setData] = useState<IPredictionData>(defaultContext);

	const update = (context: IPredictionData) => {
		setData(context);
	};

	const setStep = useCallback(
		(step: PredictionStep) => {
			setData({ ...data, step });
		},
		[data],
	);

	return <PredictionContext.Provider value={{ data, update, setStep }}>{props.children}</PredictionContext.Provider>;
}
