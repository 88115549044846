import TransitionsLogoBlack from "assets/images/logos/transitions-logo-black.svg";
import classNames from "classnames";
import Button, { EButtonVariant } from "components/elements/Button";
import LazyImage from "components/elements/LazyImage";
import { clearAllCookies } from "utils/Cookies";
import classes from "./classes.module.scss";
import { useNavigate } from "react-router-dom";
import { useCallback } from "react";
import ModuleConfig from "configs/ModuleConfig";
import { container } from "tsyringe";
const moduleConfig = container.resolve(ModuleConfig);

type IProps = {
	className?: string;
};

export default function Header(props: IProps) {
	const navigate = useNavigate();

	const onButtonClick = useCallback(() => {
		clearAllCookies();

		// Check path, if already in home, reload the page
		if (window.location.pathname === moduleConfig.get().modules.pages.Home.props.path) {
			window.location.reload();
		} else {
			navigate(moduleConfig.get().modules.pages.Home.props.path);
		}
	}, [navigate]);

	return (
		<header className={classNames(classes["root"], props.className)}>
			<LazyImage src={TransitionsLogoBlack} className={classes["logo"]} />
			<Button variant={EButtonVariant.CONTAINED} small onClick={onButtonClick}>
				New session
			</Button>
		</header>
	);
}
