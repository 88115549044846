import ApplicationConfig from "configs/ApplicationConfig";
import { container } from "tsyringe";

export enum ContentType {
	Json = "application/json",
	FormData = "multipart/form-data;",
}

export default abstract class BaseApi {
	protected readonly apiUrl = `${container.resolve(ApplicationConfig).get().api.rootUrl}/api/v1`;
	protected buildHeaders(contentType: ContentType) {
		const headers = new Headers();

		if (contentType === ContentType.Json) {
			headers.set("Content-Type", contentType);
		}

		return headers;
	}

	protected buildBody(body: { [key: string]: unknown }): string {
		return JSON.stringify(body);
	}

	protected async getRequest<T>(url: URL | string) {
		const request = async () => {
			return fetch(url, {
				method: "GET",
				credentials: "include",
				headers: this.buildHeaders(ContentType.Json),
			});
		};

		return request().then((response) => this.processResponse<T>(response));
	}

	protected async postRequest<T>(url: URL | string, body: { [key: string]: unknown } = {}) {
		const request = async () => {
			return fetch(url, {
				method: "POST",
				credentials: "include",
				headers: this.buildHeaders(ContentType.Json),
				body: this.buildBody(body),
			});
		};

		return request().then((response) => this.processResponse<T>(response));
	}

	protected async putRequest<T>(url: URL | string, body: { [key: string]: unknown } = {}) {
		const request = async () => {
			return fetch(url, {
				method: "PUT",
				credentials: "include",
				headers: this.buildHeaders(ContentType.Json),
				body: this.buildBody(body),
			});
		};

		return request().then((response) => this.processResponse<T>(response));
	}

	protected async patchRequest<T>(url: URL | string, body: { [key: string]: unknown } = {}) {
		const request = async () => {
			return fetch(url, {
				method: "PATCH",
				credentials: "include",
				headers: this.buildHeaders(ContentType.Json),
				body: this.buildBody(body),
			});
		};

		return request().then((response) => this.processResponse<T>(response));
	}

	protected async deleteRequest<T>(url: URL | string, body: { [key: string]: unknown } = {}) {
		const request = async () => {
			return fetch(url, {
				method: "DELETE",
				credentials: "include",
				headers: this.buildHeaders(ContentType.Json),
				body: this.buildBody(body),
			});
		};

		return request().then((response) => this.processResponse<T>(response));
	}

	protected async patchFormDataRequest<T>(url: URL | string, body: FormData) {
		const request = async () => {
			return fetch(url, {
				method: "PATCH",
				credentials: "include",
				headers: this.buildHeaders(ContentType.FormData),
				body,
			});
		};

		return request().then((response) => this.processResponse<T>(response));
	}

	protected async processResponse<T>(response: Response): Promise<T> {
		let responseContent: unknown;

		await this.dispatchCookieOnChangeEvent();

		if (response.headers.get("content-type")?.includes("application/json")) {
			responseContent = await response.json();
		} else {
			responseContent = await response.text();
		}

		if (!response.ok) {
			return Promise.reject(responseContent);
		}

		return responseContent as T;
	}

	protected dispatchCookieOnChangeEvent = (() => {
		let previousSessionId: string | null = null;

		const getSessionId = () => {
			let sessionId: string | undefined = undefined;

			document.cookie.split(";").forEach((cookie) => {
				const [key, value] = cookie.split("=").map((c) => c.trim());

				if (key !== "sessionId") return;
				sessionId = value;
			});
			if (!sessionId) return null;
			return decodeURIComponent(sessionId);
		};

		const mayDispatchEvent = async () => {
			try {
				const sessionId = getSessionId();
				if (previousSessionId !== sessionId) {
					previousSessionId = sessionId;
					document.dispatchEvent(
						new CustomEvent("session_id_change", {
							detail: sessionId,
						}),
					);
				}

				return Promise.resolve(sessionId);
			} catch (error) {
				return Promise.reject(error);
			}
		};

		mayDispatchEvent();
		return mayDispatchEvent;
	})();
}
