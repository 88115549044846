import Button, { EButtonVariant } from "components/elements/Button";
import { PredictionContext } from "contexts/Prediction";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { EFaceShape } from "utils/EFaceShape";
import { useChatWithAi } from "utils/HandleAiResponse";
import Header from "../Header";
import PhotoButton from "../PhotoButton";
import Theia from "../Theia";
import classes from "./classes.module.scss";
import getAudio from "utils/GetAudio";

const FaceScan = () => {
	const [faceshapeData, setFaceshapeData] = useState<{ faceshape: EFaceShape | null; facePoints: { contourPoints: any[] } } | null>(null);
	const chatWithAi = useChatWithAi();
	const predictionContext = useContext(PredictionContext);
	const [hasAgreedPrivacyTerms, setHasAgreedPrivacyTerms] = useState(false);
	const fitmixInstance = useRef<any>(null);
	const [waitFaceshape, setWaitFaceshape] = useState(false);
	useEffect(() => {
		if (fitmixInstance.current) return;
		const params = {
			apiKey: "ImSaoknBCEZtyX3wpUknDZj4dHtWv7Yk3kuhAN5r",
			onAgreePrivacyTerms: () => setHasAgreedPrivacyTerms(true),
			onGetFaceshape: async (data: any) => {
				if (data.errorCode) {
					fitmixInstance.current.resetFaceshape();
					const errorMessage =
						"It appears the photo didn't work out as we expected. Ton ensure a successful face shape detection, please position your face within the guiding markers on the camera.";

					await getAudio(errorMessage);
					predictionContext.update({ ...predictionContext.data, message: errorMessage });
					setWaitFaceshape(false);
				} else {
					chatWithAi("Photo taken")
						.then(() => setFaceshapeData(data))
						.catch((e) => console.error("chatWithAi error", e))
						.finally(() => setWaitFaceshape(false));
				}
			},
		};

		//@ts-ignore
		fitmixInstance.current = window.FitMix.createWidget("fitmixContainer", params, function (liveCompatible, advanced) {
			fitmixInstance.current.setMode("faceshape");
		});
	}, [chatWithAi, predictionContext]);

	const resetFaceshape = useCallback(() => {
		setFaceshapeData(null);
		fitmixInstance.current.resetFaceshape();
	}, [fitmixInstance]);

	const getFaceshape = useCallback(() => {
		setWaitFaceshape(true);
		setFaceshapeData(null);
		fitmixInstance.current.getFaceshape();
	}, [fitmixInstance]);

	const onContinue = useCallback(() => {
		predictionContext.data.audio?.pause();
		chatWithAi(`Photo good. Face shape is ${faceshapeData?.faceshape}`);
	}, [chatWithAi, faceshapeData, predictionContext]);

	return (
		<div className={classes["root"]}>
			<Header />
			<div className={classes["content-container"]}>
				<div className={classes["mainContainer"]}>
					<div id="fitmixContainer" className={classes["fitmixContainer"]}></div>
					{hasAgreedPrivacyTerms && (
						<div className={classes["fitmixInterface"]}>
							{!faceshapeData?.faceshape && <div className={classes["ellipsis"]}></div>}
							<div className={classes["action-container"]}>
								<div className={classes["theia-container"]}>
									<Theia vocalAnimationWhite />
									<p className={classes["text"]}>{predictionContext.data.message}</p>
								</div>
								{!waitFaceshape && !faceshapeData?.faceshape && <PhotoButton onClick={getFaceshape} />}
								{!waitFaceshape && faceshapeData?.faceshape && (
									<div className={classes["buttons-container"]}>
										<Button variant={EButtonVariant.SECONDARY} fullwidth onClick={resetFaceshape}>
											TRY AGAIN
										</Button>
										<Button variant={EButtonVariant.CONTAINED} fullwidth onClick={onContinue}>
											YES, CONTINUE
										</Button>
									</div>
								)}
							</div>

							{faceshapeData?.facePoints.contourPoints.length && (
								<div className={classes["pointsContainer"]}>
									{faceshapeData?.facePoints.contourPoints.map((point, index) => (
										<div key={index} className={classes["pointDraw"]} style={{ left: point.x, top: point.y }}></div>
									))}
								</div>
							)}
						</div>
					)}
				</div>

				{/* 
				<div className={classes["skin-tone-container"]}>
					{options.map((option) => (
						<RadioZone key={option.id} id={option.id} name={radioZoneName} onChange={onRadioInputChange} label={option.label} checked={isOptionSelected(option.id)} />
					))}
				</div>
				<div className={classes["buttons-container"]}>
					<Button
						variant={EButtonVariant.CONTAINED}
						fullwidth
						onClick={() => {
							chatWithAi(`Skin tone is ${options[parseInt(selectedOption) - 1]!.value}`);
						}}>
						Confirm
					</Button>
					<MicrophoneButton
						onEnd={(transcript) => {
							chatWithAi(transcript);
						}}
					/>
				</div> */}
			</div>
		</div>

		// <div className={classes["root"]}>
		// 	FanScan
		// 	{predictionContext.data.message && <div>{predictionContext.data.message}</div>}
		// 	<div className={classes["lineContainer"]}>
		// 		<button onClick={() => fitmixInstance.current.setMode("faceshape")}>setMode('faceshape')</button>
		// 		<button onClick={() => getFaceshape()}>getFaceshape</button>
		// 		<button onClick={() => resetFaceshape()}>resetFaceshape</button>
		// 		<button onClick={() => fitmixInstance.current.setMode("live")}>setMode('live')</button>
		// 		<div className={classes["faceshapeModeValue"]}>Faceshape status</div>
		// 	</div>
		// 	<div id="lineContainer" className={classes["lineContainer"]}></div>

		// 	<div>
		// 		<button
		// 			onClick={async () => {
		// 				const apiResponse = await AiService.getInstance().ask(`Photo good. Face shape is ${faceshap}`);

		// 				const nextStep = handleAiResponse(apiResponse);
		// 				if (apiResponse.parsedResponse?.response) {
		// 					await getAudio(apiResponse.parsedResponse?.response);
		// 					predictionContext.update({ ...predictionContext.data, message: apiResponse.parsedResponse.response });
		// 				}
		// 				if (nextStep) {
		// 					predictionContext.update({ ...predictionContext.data, step: nextStep, message: apiResponse.parsedResponse?.response ?? null });
		// 				}
		// 			}}>
		// 			Keep
		// 		</button>
		// 	</div>
		// </div>
	);
};

export default FaceScan;
