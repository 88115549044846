import React, { PropsWithChildren, useState } from "react";
type IProps = PropsWithChildren;
export type ILoaderContext = {
	loading: boolean;
};

export const LoaderContext = React.createContext({ loading: false, setLoading: (isLoading: boolean) => {} });

export function Loader(props: IProps) {
	const [loading, setLoading] = useState<boolean>(false);

	return <LoaderContext.Provider value={{ loading, setLoading }}>{props.children}</LoaderContext.Provider>;
}
