import classNames from "classnames";
import React from "react";

import classes from "./classes.module.scss";

export enum EButtonVariant {
	CONTAINED = "contained",
	OUTLINED = "outlined",
	SECONDARY = "secondary",
}

type IProps = {
	onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
	children?: React.ReactNode;
	variant?: EButtonVariant;
	fullwidth?: boolean;
	icon?: JSX.Element;
	disabled?: boolean;
	type?: "button" | "submit";
	isloading?: string;
	iconposition?: "left" | "right";
	className?: string;
	small?: boolean;
};

export default function Button(props: IProps) {
	let {
		variant = EButtonVariant.CONTAINED,
		disabled = false,
		type = "button",
		isloading = "false",
		fullwidth = false,
		iconposition = "right",
		onClick,
		children,
		icon,
		className = "",
	} = props;

	const fullwidthattr = fullwidth.toString();
	const isloadingattr = isloading.toString();

	const attributes = { ...props, variant, disabled, type, isloadingattr, fullwidthattr };
	delete attributes.fullwidth;
	delete attributes.icon;
	delete attributes.iconposition;
	delete attributes.small;

	return (
		<button
			{...attributes}
			onClick={onClick}
			className={classNames(classes["root"], className, {
				[classes["small"]!]: props.small,
			})}
			type={type}>
			{icon && iconposition === "left" && icon}
			{children}
			{icon && iconposition === "right" && icon}
		</button>
	);
}
