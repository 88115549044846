import BaseApi from "./BaseApi";

export enum AiResponseAction {
	START_DIAGNOSIS = "start_diagnosis",
	FACE_SHAPE_WIDGET = "face_shape_widget",
	SKIN_TONE_WIDGET = "skin_tone_widget",
	FRAME_GENDER_WIDGET = "frame_gender_widget",
	FRAME_STYLE_WIDGET = "frame_style_widget",
	RETRIEVE_USER_DATA = "retrieve_user_data",
	ADVICE_GLASSES_BY_ID_WIDGET = "advice_glasses_by_id_widget",
	NONE = "",
}
export type Action = {
	name: AiResponseAction;
	arguments: any[];
};
export type AiParsedResponse = {
	response: string | null;
	action: Action | null;
	parsingError: any | null;
};

export type Answer = {
	parsedResponse: AiParsedResponse | null;
	sessionId: string;
};

export default class AiApi extends BaseApi {
	private static instance: AiApi;
	public readonly openAiUrl = `${this.apiUrl}/ai/openai`;
	public readonly mistralAiUrl = `${this.apiUrl}/ai/mistralai`;

	private constructor() {
		super();
		AiApi.instance = this;
	}

	public static getInstance(reset?: boolean) {
		if (!AiApi.instance || reset) return new this();
		return AiApi.instance;
	}

	public async ask(content: string): Promise<Answer> {
		const url = this.mistralAiUrl.concat("/ask");
		const response = await this.postRequest<Answer>(url, { role: "user", content });
		return response;
	}

	public async predict(): Promise<Answer> {
		const url = this.mistralAiUrl.concat("/predict");
		const response = await this.getRequest<Answer>(url);
		return response;
	}
}
