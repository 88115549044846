import Header from "../Header";
import classes from "./classes.module.scss";

type IProps = {};

export default function Start(props: IProps) {
	return (
		<div className={classes["root"]}>
			<Header className={classes["header"]} />
		</div>
	);
}
