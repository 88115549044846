import classNames from "classnames";
import classes from "./classes.module.scss";
import { useCallback } from "react";
import LazyImage from "components/elements/LazyImage";
import DiscreetGlassesImage from "assets/images/glasses/discreet.png";
import FlashyGlassesImage from "assets/images/glasses/flashy.png";
import IconicGlassesImage from "assets/images/glasses/iconic.png";
import OversizedGlassesImage from "assets/images/glasses/oversized.png";

type IProps = {
	id: string;
	name: string;
	checked: boolean;
	onChange: React.ChangeEventHandler<HTMLInputElement>;
	label: string;
};

const glassesImages = [FlashyGlassesImage, OversizedGlassesImage, IconicGlassesImage, DiscreetGlassesImage];

export default function CheckboxZone(props: IProps) {
	const selectedImage = glassesImages[Number(props.id) - 1];

	const handleClick = useCallback(
		(event: React.MouseEvent) => {
			event.preventDefault();
			document.getElementById(props.id)?.click();
		},
		[props.id],
	);

	return (
		<div onClick={handleClick} className={classes["root"]}>
			{/* 
                Checkbox buttons are hidden and custom styles are applied to the labels. 
                This allows for full customization of the checkbox button appearance 
                while maintaining the original input functionality.
            */}
			<input type="checkbox" name={props.name} checked={props.checked} onChange={props.onChange} id={props.id} className={classes["checkbox-input"]} />
			<div className={classes["image-container"]}>
				{selectedImage && <LazyImage src={selectedImage} className={classes["glasses"]} />}
				<ShadowSVG />
			</div>
			<div className={classes["content-container"]}>
				<p className={classes["text"]}>{props.label}</p>
				<label
					htmlFor={props.id}
					className={classNames(classes["checkbox-label"], {
						[classes["checked"]!]: props.checked,
					})}
				/>
			</div>
		</div>
	);
}

const ShadowSVG = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="129" height="31" viewBox="0 0 129 31" fill="none">
			<g filter="url(#filter0_f_208_23510)">
				<ellipse cx="64.5001" cy="15.4285" rx="54.0909" ry="5" fill="url(#paint0_radial_208_23510)" />
			</g>
			<defs>
				<filter id="filter0_f_208_23510" x="0.40918" y="0.428467" width="128.182" height="30" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
					<feFlood floodOpacity="0" result="BackgroundImageFix" />
					<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
					<feGaussianBlur stdDeviation="5" result="effect1_foregroundBlur_208_23510" />
				</filter>
				<radialGradient
					id="paint0_radial_208_23510"
					cx="0"
					cy="0"
					r="1"
					gradientUnits="userSpaceOnUse"
					gradientTransform="translate(64.5001 15.4285) rotate(90) scale(5 54.0909)">
					<stop stopColor="#CBCBCB" />
					<stop offset="1" stopColor="#A7A7A7" stopOpacity="0" />
				</radialGradient>
			</defs>
		</svg>
	);
};
