import MicrophoneIcon from "assets/images/icons/microphone.svg";
import VocalAnimation from "assets/images/vocal-animation.gif";
import LazyImage from "components/elements/LazyImage";
import { useRef, useState } from "react";
import classes from "./classes.module.scss";
import AiApi from "api/transitions/AiApi";

type IProps = {
	onStart?: () => void;
	onEnd?: (transcript: string) => void;
};

export default function MicrophoneButton(props: IProps) {
	const [isListening, setIsListening] = useState(false);
	const mediaRecorderRef = useRef<MediaRecorder | null>(null);
	const chunksRef = useRef<any[]>([]);

	const startListening = async () => {
		props.onStart?.();
		setIsListening(true);
		const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
		const mediaRecorder = new MediaRecorder(stream);
		chunksRef.current = [];

		mediaRecorder.ondataavailable = (event) => {
			if (event.data.size > 0) {
				chunksRef.current.push(event.data);
			}
		};

		mediaRecorder.onstop = async () => {
			const audioBlob = new Blob(chunksRef.current, { type: "audio/wav" });
			const backendEndpoint = `${AiApi.getInstance().openAiUrl}/get-text`;
			try {
				const formData = new FormData();
				formData.append("file", audioBlob, "audio.wav");

				const response = await fetch(backendEndpoint, {
					headers: {
						"Accept-Language": "en-US",
					},
					method: "POST",
					body: formData,
				});

				const jsonResponse = await response.json();

				if (props.onEnd) {
					props.onEnd(jsonResponse.text);
				}
			} catch (error) {
				console.error("Error sending audio to the backend:", error);
			}
		};

		mediaRecorderRef.current = mediaRecorder;
		mediaRecorderRef.current.start();
	};

	const stopListening = () => {
		mediaRecorderRef.current?.stop();
		setIsListening(false);
	};

	const toggleListening = () => {
		if (isListening) {
			stopListening();
		} else {
			startListening();
		}
	};

	return (
		<button onClick={toggleListening} className={classes["root"]}>
			{!isListening && <LazyImage src={MicrophoneIcon} className={classes["icon"]} />}
			{isListening && <LazyImage src={VocalAnimation} />}
		</button>
	);
}
