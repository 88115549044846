import PageTemplate from "components/PageTemplate";
import I18n from "components/materials/I18n";
import { memo, useCallback, useContext, useEffect } from "react";
import classes from "./classes.module.scss";
import Button, { EButtonVariant } from "components/elements/Button";
import Header from "components/materials/Header";
import { clearAllCookies } from "utils/Cookies";
import { useNavigate } from "react-router-dom";
import { container } from "tsyringe";
import ModuleConfig from "configs/ModuleConfig";
import Theia from "components/materials/Theia";
import { PredictionContext } from "contexts/Prediction";
import MicrophoneButton from "components/materials/MicrophoneButton";
import { LoaderContext } from "contexts/Loader";
import LoadingOverlay from "components/materials/LoadingOverlay";
import { useChatWithAi } from "utils/HandleAiResponse";
const moduleConfig = container.resolve(ModuleConfig);

function ThankYou() {
	const navigate = useNavigate();
	const predictionContext = useContext(PredictionContext);
	const loaderContext = useContext(LoaderContext);
	const chatWithAi = useChatWithAi();

	useEffect(
		() => {
			chatWithAi("I'm done with the step advice_glasses_by_id_widget DISCUSSION CONFIG");
		},
		// We want this useEffect to run only once, so we pass an empty array as the second argument
		// eslint-disable-next-line
		[],
	);

	const onButtonClick = useCallback(() => {
		clearAllCookies();
		navigate(moduleConfig.get().modules.pages.Home.props.path);
	}, [navigate]);

	const onMicrophoneStart = useCallback(() => predictionContext.data.audio?.pause(), [predictionContext.data.audio]);
	const onMicrophoneEnd = useCallback((transcript: string) => chatWithAi(transcript), [chatWithAi]);

	return (
		<PageTemplate tabTitle={I18n.translate("pages.thankyou.page_title")}>
			{loaderContext.loading && <LoadingOverlay />}
			<div className={classes["root"]}>
				<Header className={classes["header"]} />

				<div className={classes["content"]}>
					<div className={classes["content-container"]}>
						<Theia />

						<div className={classes["text-container"]}>
							<p className={classes["text"]}>{predictionContext.data.message}</p>
						</div>
					</div>

					<div className={classes["buttons-container"]}>
						<Button variant={EButtonVariant.CONTAINED} onClick={onButtonClick} fullwidth>
							NEW DIAGNOSIS
						</Button>
						<MicrophoneButton onStart={onMicrophoneStart} onEnd={onMicrophoneEnd} />
					</div>
				</div>
			</div>
		</PageTemplate>
	);
}

export default memo(ThankYou);
