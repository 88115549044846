import { singleton } from "tsyringe";
import developmentConfig from "./development.json";
import preprodConfig from "./preprod.json";
import stagingConfig from "./staging.json";
import productionConfig from "./production.json";

@singleton()
export default class ApplicationConfig {
	private config: typeof developmentConfig = developmentConfig;
	public constructor() {
		this.setConfig();
	}

	public get() {
		return this.config;
	}

	private setConfig() {
		switch (process.env["REACT_APP_ENV"]) {
			case "staging":
				this.config = stagingConfig;
				break;
			case "preprod":
				this.config = preprodConfig;
				break;
			case "production":
				this.config = productionConfig;
				break;
			default:
				this.config = developmentConfig;
				break;
		}
	}
}
