import { AuthProvider } from "contexts/Auth";
import { Prediction } from "contexts/Prediction";
import Routing from "./Routing";
import "./di/register";
import { Loader } from "contexts/Loader";

function App() {
	return (
		<AuthProvider>
			<Prediction>
				<Loader>
					<Routing />
				</Loader>
			</Prediction>
		</AuthProvider>
	);
}

export default App;
