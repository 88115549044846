import { memo } from "react";
import I18n from "components/materials/I18n";
import classes from "./classes.module.scss";
import useHead from "hooks/useHead";

type IProps = {
	children?: React.ReactNode;
	tabTitle?: string;
};

function PageTemplate(props: IProps) {
	useHead({
		title: props.tabTitle ?? "transitions",
	});

	return (
		<I18n>
			<main className={classes["root"]}>{props.children}</main>
		</I18n>
	);
}

export default memo(PageTemplate);
