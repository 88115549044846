import LazyImage from "components/elements/LazyImage";
import classes from "./classes.module.scss";
import TransitionsLogoWhite from "assets/images/logos/transitions-logo-white.svg";
import GradiantBackground from "assets/images/backgrounds/bg.png";
import VolumeIcon from "assets/images/icons/volume.gif";
import Button, { EButtonVariant } from "components/elements/Button";

type IProps = {
	onStart: () => void;
};

export default function StartExperience(props: IProps) {
	return (
		<div className={classes["root"]}>
			<LazyImage src={GradiantBackground} className={classes["background"]} />
			<LazyImage src={TransitionsLogoWhite} className={classes["logo"]} />

			<div className={classes["text-container"]}>
				<LazyImage src={VolumeIcon} className={classes["volume-icon"]} />
				<p className={classes["text"]}>
					Increase volume for
					<br />
					optimum experience
				</p>
			</div>

			<div className={classes["button-container"]}>
				<Button variant={EButtonVariant.CONTAINED} fullwidth onClick={props.onStart}>
					Start the experiment
				</Button>
			</div>
		</div>
	);
}
