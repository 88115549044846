import CameraIcon from "assets/images/icons/camera.svg";
import LazyImage from "components/elements/LazyImage";
import classes from "./classes.module.scss";

type IProps = {
	onClick: () => void;
};

export default function PhotoButton(props: IProps) {
	return (
		<button onClick={props.onClick} className={classes["root"]}>
			<LazyImage src={CameraIcon} />
		</button>
	);
}
