import Home from "components/pages/Home";
import Page404 from "components/pages/Page404";
import ThankYou from "components/pages/ThankYou";
import ModuleConfig from "configs/ModuleConfig";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { container } from "tsyringe";

const moduleConfig = container.resolve(ModuleConfig);

const router = createBrowserRouter([
	{
		path: moduleConfig.get().modules.pages.Home.props.path,
		element: <Home />,
	},
	{
		path: moduleConfig.get().modules.pages.ThankYou.props.path,
		element: <ThankYou />,
	},
	{
		path: "*",
		element: <Page404 />,
	},
]);

export default function Routing() {
	return <RouterProvider router={router} />;
}
