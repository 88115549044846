import { createRef, useCallback } from "react";
import classes from "./classes.module.scss";
import classNames from "classnames";

type IProps = {
	value: number;
	onChange: (value: number) => void;
};

export default function Slider(props: IProps) {
	const minValue = 0;
	const maxValue = 4;
	const stepValue = 1;

	const inputRef: React.RefObject<HTMLInputElement> = createRef();

	const onChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			const value = e.target.value;
			props.onChange(parseInt(value));
		},
		[props],
	);

	return (
		<div className={classes["root"]}>
			<input
				ref={inputRef}
				onChange={onChange}
				className={classNames(classes["input"], {
					[classes["fair"]!]: props.value === 0,
					[classes["light-medium"]!]: props.value === 1,
					[classes["medium"]!]: props.value === 2,
					[classes["tan"]!]: props.value === 3,
					[classes["dark"]!]: props.value === 4,
				})}
				type="range"
				step={stepValue}
				min={minValue}
				max={maxValue}
				value={props.value}
			/>
			<div className={classes["legends-container"]}>
				<p className={classes["legend"]}>Fair</p>
				<p className={classes["legend"]}>Medium</p>
				<p className={classes["legend"]}>Dark</p>
			</div>
		</div>
	);
}
