import LazyImage from "components/elements/LazyImage";
import classes from "./classes.module.scss";
import TheiaAvatar from "assets/images/theia.png";
import VocalAnimation from "assets/images/vocal-animation.gif";
import VocalAnimationWhite from "assets/images/vocal-animation-white.gif";
import { useContext, useEffect, useState, useCallback } from "react";
import { PredictionContext } from "contexts/Prediction";
import VocalPlay from "assets/images/vocal-play.png";
import VocalPlayWhite from "assets/images/vocal-play-white.png";

type IProps = {
	vocalAnimationWhite?: boolean;
};

export default function Theia(props: IProps) {
	const predictionContext = useContext(PredictionContext);
	// predictionContext.data.audio?.paused can be undefined, the '!' convert undefined to true
	// const [isPlaying, setIsPlaying] = useState(!predictionContext.data.audio?.paused ?? false);
	const [isPlaying, setIsPlaying] = useState(false);
	const audio = predictionContext.data.audio;

	useEffect(() => {
		if (!audio) return;

		setIsPlaying(!audio.paused);

		const play = () => setIsPlaying(true);
		const pause = () => setIsPlaying(false);

		audio.addEventListener("play", play);
		audio.addEventListener("pause", pause);
		audio.addEventListener("ended", pause);

		return () => {
			audio.removeEventListener("play", play);
			audio.removeEventListener("pause", pause);
			audio.removeEventListener("ended", pause);
		};
	}, [audio]);

	const togglePlay = useCallback(() => {
		if (!audio) return;
		if (audio.paused) return audio.play();
		return audio.pause();
	}, [audio]);

	return (
		<div className={classes["root"]}>
			<LazyImage src={TheiaAvatar} alt="Theia" className={classes["theia-avatar"]} />
			<div onClick={togglePlay} className={classes["play-button"]}>
				{!isPlaying && props.vocalAnimationWhite && <LazyImage src={VocalPlayWhite} className={classes["vocal-animation"]} />}
				{!isPlaying && !props.vocalAnimationWhite && <LazyImage src={VocalPlay} className={classes["vocal-animation"]} />}
				{isPlaying && props.vocalAnimationWhite && <LazyImage src={VocalAnimationWhite} className={classes["vocal-animation"]} />}
				{isPlaying && !props.vocalAnimationWhite && <LazyImage src={VocalAnimation} className={classes["vocal-animation"]} />}
			</div>
		</div>
	);
}
