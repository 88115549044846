import AiApi from "api/transitions/AiApi";

let prevAudio: HTMLAudioElement | null = null;

const getAudio = async (text: string) => {
	if (prevAudio) prevAudio.pause();
	const response = await fetch(`${AiApi.getInstance().openAiUrl}/get-audio`, {
		method: "POST",
		body: JSON.stringify({
			text: text,
		}),
		headers: { "Content-Type": "application/json" },
	});

	if (!response.ok) {
		throw new Error("Network response was not ok");
	}
	const blob = await response.blob();
	const audioBlob = new Blob([blob], { type: "audio/mpeg" }); // Remplacer par le type MIME approprié
	const audioUrl = URL.createObjectURL(audioBlob);
	const audio = new Audio(audioUrl);
	audio.volume = 1;
	prevAudio = audio;

	// Don't delete the .catch() block, it's necessary. (seriously don't touch it).
	await audio.play().catch((error) => {
		console.log("Error playing audio: ", error);
	});

	return audio;
};

export default getAudio;
