import PageTemplate from "components/PageTemplate";
import AcceptConsent from "components/materials/AcceptConsent";
import AdviceGlasses from "components/materials/AdviceGlasses";
import Discussion from "components/materials/Discussion";
import FaceScan from "components/materials/FaceScan";
import FrameGender from "components/materials/FrameGender";
import FrameStyle from "components/materials/FrameStyle";
import I18n from "components/materials/I18n";
import LoadingOverlay from "components/materials/LoadingOverlay";
import Predict from "components/materials/Predict";
import SkinTone from "components/materials/SkinTone";
import Start from "components/materials/Start";
import StartExperience from "components/materials/StartExperience";
import { LoaderContext } from "contexts/Loader";
import { PredictionContext, PredictionStep } from "contexts/Prediction";
import { memo, useCallback, useContext, useState } from "react";
import { clearAllCookies } from "utils/Cookies";
import { useChatWithAi } from "utils/HandleAiResponse";

function Home() {
	const chatWithAi = useChatWithAi();
	const loaderContext = useContext(LoaderContext);
	const predictionContext = useContext(PredictionContext);
	const [hasAcceptConsent, setHasAcceptConsent] = useState(false);
	const [hasStartExperience, setHasStartExperience] = useState(false);

	const isStep = useCallback(
		(predictionStep: PredictionStep, stepExpected: PredictionStep) => {
			return hasAcceptConsent && hasStartExperience && predictionStep === stepExpected;
		},
		[hasAcceptConsent, hasStartExperience],
	);

	const onAcceptConsent = useCallback(() => {
		setHasAcceptConsent(true);
		clearAllCookies();
	}, []);

	const startExperiment = useCallback(async () => {
		await chatWithAi("Hello GLASSES CONFIG", PredictionStep.DISCUSSION);
		setHasStartExperience(true);
	}, [chatWithAi]);

	return (
		<PageTemplate tabTitle={I18n.translate("pages.home.page_title")}>
			{loaderContext.loading && <LoadingOverlay />}
			{!hasAcceptConsent && <AcceptConsent onAccept={onAcceptConsent} />}
			{hasAcceptConsent && !hasStartExperience && <StartExperience onStart={startExperiment} />}
			{isStep(predictionContext.data.step, PredictionStep.START) && <Start />}
			{isStep(predictionContext.data.step, PredictionStep.DISCUSSION) && <Discussion />}
			{isStep(predictionContext.data.step, PredictionStep.START_DIAGNOSIS) && <Discussion />}
			{isStep(predictionContext.data.step, PredictionStep.FACE_SCAN) && <FaceScan />}
			{isStep(predictionContext.data.step, PredictionStep.SKIN_TONE) && <SkinTone />}
			{isStep(predictionContext.data.step, PredictionStep.FRAME_GENDER) && <FrameGender />}
			{isStep(predictionContext.data.step, PredictionStep.FRAME_STYLE) && <FrameStyle />}
			{isStep(predictionContext.data.step, PredictionStep.RETRIEVE_USER_DATA) && <Predict />}
			{isStep(predictionContext.data.step, PredictionStep.ADVICE_GLASSES_BY_ID_WIDGET) && <AdviceGlasses />}
		</PageTemplate>
	);
}

export default memo(Home);
