import AiApi, { AiResponseAction, Answer } from "api/transitions/AiApi";
import { PredictionContext, PredictionStep } from "contexts/Prediction";
import { useContext } from "react";
import getAudio from "./GetAudio";
import { LoaderContext } from "contexts/Loader";

export const handleAiResponse = (response: Answer) => {
	switch (response.parsedResponse?.action?.name) {
		case AiResponseAction.FACE_SHAPE_WIDGET:
			return PredictionStep.FACE_SCAN;
		case AiResponseAction.START_DIAGNOSIS:
			return PredictionStep.START_DIAGNOSIS;
		case AiResponseAction.SKIN_TONE_WIDGET:
			return PredictionStep.SKIN_TONE;
		case AiResponseAction.FRAME_GENDER_WIDGET:
			return PredictionStep.FRAME_GENDER;
		case AiResponseAction.FRAME_STYLE_WIDGET:
			return PredictionStep.FRAME_STYLE;
		case AiResponseAction.RETRIEVE_USER_DATA:
			return PredictionStep.RETRIEVE_USER_DATA;
		case AiResponseAction.ADVICE_GLASSES_BY_ID_WIDGET:
			return PredictionStep.ADVICE_GLASSES_BY_ID_WIDGET;
		default:
			return null;
	}
};

export const useChatWithAi = () => {
	const predictionContext = useContext(PredictionContext);
	const loaderContext = useContext(LoaderContext);

	return async function chat(text: string, nextStepArg?: PredictionStep) {
		loaderContext.setLoading(true);
		const apiResponse = await AiApi.getInstance().ask(text);
		const nextStep = nextStepArg ?? handleAiResponse(apiResponse);
		if (apiResponse.parsedResponse?.response) {
			const audio = await getAudio(apiResponse.parsedResponse?.response);
			predictionContext.update({ ...predictionContext.data, audio, message: apiResponse.parsedResponse.response, step: nextStep ?? predictionContext.data.step });
		}
		loaderContext.setLoading(false);
	};
};

export const usePredict = () => {
	const predictionContext = useContext(PredictionContext);

	return async function predict() {
		const apiResponse = await AiApi.getInstance().predict();
		const nextStep = handleAiResponse(apiResponse);

		if (apiResponse.parsedResponse?.response) {
			await getAudio(apiResponse.parsedResponse?.response);
			predictionContext.update({
				...predictionContext.data,
				message: apiResponse.parsedResponse.response,
				step: nextStep ?? predictionContext.data.step,
				recommandedGlasses: apiResponse.parsedResponse?.action?.arguments ?? [],
			});
		}
	};
};
