import Button, { EButtonVariant } from "components/elements/Button";
import Header from "../Header";
import Theia from "../Theia";
import classes from "./classes.module.scss";
import MicrophoneButton from "../MicrophoneButton";
import CheckboxZone from "./CheckboxZone";
import { useCallback, useContext, useState } from "react";
import { useChatWithAi } from "utils/HandleAiResponse";
import { PredictionContext } from "contexts/Prediction";
const options = [
	{
		id: "1",
		value: "male",
		label: "Male",
	},
	{
		id: "2",
		value: "female",
		label: "Female",
	},
	{
		id: "3",
		value: "unisex",
		label: "Unisex",
	},
];

type IProps = {};

export default function FrameGender(props: IProps) {
	const checkboxZoneName = "frame-gender-option";
	const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
	const chatWithAi = useChatWithAi();
	const predictionContext = useContext(PredictionContext);

	const isOptionSelected = useCallback((optionId: string) => selectedOptions.includes(optionId), [selectedOptions]);
	const onCheckboxInputChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
		setSelectedOptions((prevState) => {
			const newState = [...prevState];
			const optionId = event.target.id;
			const optionIndex = newState.indexOf(optionId);
			if (optionIndex === -1) {
				newState.push(optionId);
			} else {
				newState.splice(optionIndex, 1);
			}
			return newState;
		});
	}, []);
	const stopAudio = useCallback(() => predictionContext.data.audio?.pause(), [predictionContext.data.audio]);

	return (
		<div className={classes["root"]}>
			<Header />
			<div className={classes["content-container"]}>
				<div className={classes["theia-container"]}>
					<Theia />
					<p className={classes["text"]}>{predictionContext.data.message}</p>
				</div>
				<div className={classes["gender-container"]}>
					{options.map((option) => (
						<CheckboxZone
							key={option.id}
							id={option.id}
							name={checkboxZoneName}
							onChange={onCheckboxInputChange}
							label={option.label}
							checked={isOptionSelected(option.id)}
						/>
					))}
				</div>
				<div className={classes["buttons-container"]}>
					<Button
						variant={EButtonVariant.CONTAINED}
						fullwidth
						onClick={() => {
							stopAudio();
							chatWithAi(`Frame gender is ${selectedOptions.map((option) => options.find((o) => o.id === option)!.value).join(", ")}`);
						}}>
						Confirm
					</Button>
					<MicrophoneButton
						onStart={stopAudio}
						onEnd={(transcript) => {
							chatWithAi(transcript);
						}}
					/>
				</div>
			</div>
		</div>
	);
}
