import { singleton } from "tsyringe";

@singleton()
export default class CookieService {
	private cookie: string | null = null;
	private sessionId: string | null = null;

	public getSessionId(): string | null {
		let sessionId: string | undefined = undefined;

		// preserve recomputation of jwtPairString if cookie has not changed
		if (this.cookie === document.cookie) return this.sessionId;

		this.cookie = document.cookie;

		// else extract jwtPair from cookie
		for (const segment of this.cookie.split(";")) {
			const [key, value] = segment.split("=").map((c) => c.trim());

			if (key !== "sessionId") continue;
			sessionId = value?.trim();
			break;
		}

		if (!sessionId) return (this.sessionId = null);

		this.sessionId = decodeURIComponent(sessionId);

		return this.sessionId;
	}
}
