import React from "react";
import classes from "./classes.module.scss";

type IProps = {
	src?: File | string | null;
	alt?: string;
	className?: string;
	style?: React.CSSProperties;
	onClick?: () => void;
};

type IState = {
	src: File | string | null;
};

export default class LazyImage extends React.Component<IProps, IState> {
	private static onePixel: string =
		"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAA1JREFUGFdjYGBkZAAAAAoAAx9k7/gAAAAASUVORK5CYII=";
	public constructor(props: IProps) {
		super(props);
		this.state = {
			src: null,
		};
	}
	public override render(): JSX.Element {
		if (this.state.src) {
			return (
				<img
					src={this.state.src as string}
					alt={this.props.alt}
					className={classes["fade-in"]!.concat(" ", this.props.className ?? "")}
					style={this.props.style}
					onClick={this.props.onClick}
				/>
			);
		}
		return (
			<img
				src={LazyImage.onePixel}
				alt={this.props.alt}
				className={this.props.className}
				style={this.props.style}
				onClick={this.props.onClick}
			/>
		);
	}
	public override componentDidMount() {
		this.loadImg();
	}

	public override componentDidUpdate(prevProps: IProps) {
		if (prevProps.src === this.props.src) return;
		this.loadImg();
	}

	private loadImg() {
		const img = new Image();
		img.onload = () =>
			this.setState({
				src: this.props.src ?? null,
			});
		img.src = this.props.src as string;
	}
}
