import classes from "./classes.module.scss";
import Loader from "components/elements/Loader";

export default function LoadingOverlay() {
	return (
		<div className={classes["root"]}>
			<Loader />
		</div>
	);
}
